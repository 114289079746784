import React from 'react';
import logo from './logo.png';
import './App.css';

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <p>
                        Djamma Dev | Developer
                    </p>
                    <a
                        className="App-link"
                        href="/templates/unify"
                        rel="noopener noreferrer"
                    >
                        Unify v2.6.3
                    </a>
                    <br/>
                    <a
                        className="App-link"
                        href="/templates/unify/documentation"
                        rel="noopener noreferrer"
                    >
                        Unify v2.6.3 / Documentation
                    </a>
                    <br/>
                    <a
                        className="App-link"
                        href="/templates/epona"
                        rel="noopener noreferrer"
                    >
                        Epona
                    </a>
                </header>
            </div>
        );
    }
}

export default App;
